import { CaretDown2Icon, Font, SvgIcon, SvgIconSize } from "@hkexpressairwayslimited/ui/src";
import { Box, Button, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { FoldableTextProps } from "./d";

export const FoldableText = ({
  children,
  viewMoreText,
  viewlessText,
  isCollapsed,
  onToggle,
  sx,
}: FoldableTextProps) => {
  const [collapsed, setCollapsed] = useState(isCollapsed);
  useEffect(() => {
    setCollapsed(isCollapsed);
  }, [isCollapsed]);
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setCollapsed(!collapsed);
      onToggle && onToggle(!collapsed);
    },
    [collapsed, onToggle]
  );
  return (
    <Box
      sx={{
        overflow: "hidden",
        display: "-webkit-box",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": collapsed ? "100" : "3",
        textOverflow: "ellipsis",
        "&::before": {
          content: '""',
          float: "right",
          width: 0,
          height: "50px",
        },
        "& .btn": {
          padding: 0,
          float: "right",
          clear: "both",
          transform: "translateY(-2px)",
        },
        ...sx,
      }}
    >
      {!collapsed && (
        <Button className='btn' onClick={handleClick}>
          <Stack direction='row' alignItems='center'>
            <Font>{viewMoreText}</Font>
            <SvgIcon size={SvgIconSize.Mobile} sx={{ position: "relative", top: "-2px" }}>
              <CaretDown2Icon />
            </SvgIcon>
          </Stack>
        </Button>
      )}
      {children}
      {collapsed && (
        <Button sx={{ padding: 0 }} onClick={handleClick}>
          <Stack direction='row' alignItems='center'>
            <Font>{viewlessText}</Font>
            <SvgIcon size={SvgIconSize.Mobile} sx={{ transform: "rotate(180deg)", position: "relative", top: "-2px" }}>
              <CaretDown2Icon />
            </SvgIcon>
          </Stack>
        </Button>
      )}
    </Box>
  );
};
