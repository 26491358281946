import { ESpacing } from "@hkexpressairwayslimited/ui/src";
import { EFont } from "@hkexpressairwayslimited/ui/src/themes/typography";
import { Typography as MuiTypography, TypographyProps, styled } from "@mui/material";
import { forwardRef } from "react";
const FontHTMLMapping: { [key in EFont]: React.ElementType } = {
  [EFont.h0]: "h1",
  [EFont.h1]: "h2",
  [EFont.h2]: "h3",
  [EFont.h3]: "h4",
  [EFont.p1]: "p",
  [EFont.p2]: "p",
  [EFont.p3]: "p",
  [EFont.l1]: "a",
  [EFont.l2]: "a",
  [EFont.l3]: "a",
  [EFont.m1]: "h5",
};
export type FontProps = {
  variant?: EFont;
} & Omit<TypographyProps, "variant">;

const StyledTypography = styled(MuiTypography)(({}) => ({
  "& > .MuiTypography-root": {
    marginX: ESpacing._3xs,
    display: "inline-block",
  },
}));
export const Font = forwardRef<HTMLDivElement, FontProps>((props, ref) => {
  const { component, variant } = props;
  const tag = (variant ? FontHTMLMapping[variant as EFont] || component : component) ?? "p";
  const newProps = {
    ...props,
    component: tag,
  };
  return <StyledTypography {...newProps} ref={ref} />;
});
Font.displayName = "Font";
