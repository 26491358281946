/* eslint-disable react/jsx-key */
import {
  AccordionExpandIcon,
  AppContext,
  Button,
  Checkbox,
  CheckmarkFilledIcon,
  EMAIL_REGEXP,
  EPixel,
  ESpacing,
  EmailIcon,
  ErrorLabel,
  FieldInputGroup,
  FieldInputGroupProps,
  FoldableText,
  Font,
  Form,
  Link,
  LinkProps,
  Section,
  SvgIcon,
  SvgIconSize,
  Text,
  TextInput,
  TextSize,
  Title,
  TitleOrders,
  colors,
  parseHtmlToReact,
  useDesktopQuery,
  useForm,
} from "@hkexpressairwayslimited/ui/src";
import {
  Box,
  FormControlLabel,
  Grid,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Stack,
  styled,
} from "@mui/material";
import { ReactElement, ReactNode, useCallback, useContext } from "react";
import * as yup from "yup";
import classes from "./Footer.module.scss";
const StyledMuiAccordion = styled(MuiAccordion)(({ theme }) => ({
  "&:before": {
    display: "none",
  },
  boxShadow: "none",
  backgroundColor: "transparent",
  "&.Mui-expanded": {
    margin: 0,
  },
  ".MuiAccordionSummary-root.Mui-expanded": {
    minHeight: "initial",
  },
  ".MuiAccordionSummary-root": {
    minHeight: "initial",
    padding: `0 ${EPixel.px24}`,
    [theme.breakpoints.down(992)]: {
      padding: `0 ${EPixel.px16}`,
    },
  },
  ".MuiAccordionSummary-content": {
    margin: `0`,
  },
  ".MuiAccordionSummary-content.Mui-expanded": {
    margin: `0`,
  },
  ".MuiAccordionDetails-root": {
    padding: `${EPixel.px12} ${EPixel.px24} 0 ${EPixel.px24}`,
    [theme.breakpoints.down(992)]: {
      padding: `${EPixel.px20} ${EPixel.px16} ${EPixel.px16} ${EPixel.px16}`,
    },
  },
  ".MuiAccordionSummary-expandIconWrapper": {
    width: EPixel.px32,
    height: EPixel.px32,
    color: "#702B91",
    transition: "none",
  },
}));
export enum SubscribeStatus {
  Success = "success",
  Fail = "fail",
  Padding = "padding",
  Error = "error",
}
export type FooterProps = {
  subscriptionField: {
    label: string | ReactElement;
    helperText: FieldInputGroupProps["helperText"];
  };
  checkboxConf: {
    label: string;
    viewMoreLabel: string | ReactElement;
    viewLessLabel: string | ReactElement;
  };
  subscribeBtnLabel: string | ReactElement;
  subscribeBtnHandle: (email: string, checked: boolean) => Promise<SubscribeStatus | undefined>;
  logo: ReactElement;
  copyrightLabel: string | ReactNode;
  navGroups?: ReactNode;
  socialShareTitle?: string | ReactElement;
  socialShareChannels?: ReactNode;
  emailFieldRequiredMsg: yup.Message<any>;
  emailFormatErrorMsg: yup.Message<any>;
  editor: {
    emailFieldRequiredMsg?: ReactNode;
  };
  subscribeStatus?: SubscribeStatus;
  subscribeStatusLabels: {
    [keyof in SubscribeStatus]?: string | ReactElement;
  } & {
    successfullyDesc: string | ReactElement;
  };
};

export type FooterNavGroupProps = {
  groupLabel: string | ReactElement;
  navLinks: ReactNode;
};

export type FooterNavLinkProps = LinkProps;

export const FooterNavLink = (props: FooterNavLinkProps) => {
  return <Link size={TextSize.P2Regular} color={colors.neutralBlack} unbold {...props} />;
};

export const FooterNavGroup = ({ groupLabel, navLinks }: FooterNavGroupProps) => {
  const isDesktop = useDesktopQuery();
  return (
    <div className={classes.navGroup}>
      {isDesktop ? (
        <>
          <Text span bold color={colors.neutralBlack}>
            {groupLabel}
          </Text>
          <div className={classes.navGroup_navLinks}>{navLinks}</div>
        </>
      ) : (
        <StyledMuiAccordion>
          <MuiAccordionSummary expandIcon={<AccordionExpandIcon />}>
            <Text span bold color={colors.neutralBlack}>
              {groupLabel}
            </Text>
          </MuiAccordionSummary>
          <MuiAccordionDetails>
            <div className={classes.navGroup_navLinks}>{navLinks}</div>
          </MuiAccordionDetails>
        </StyledMuiAccordion>
      )}
    </div>
  );
};

export type FooterShareChannelProps = {
  children: ReactElement;
};

export const FooterShareChannel = ({ children }: FooterShareChannelProps) => {
  return <div className={classes.footer_shareChannel}>{children}</div>;
};
export const Footer = ({
  copyrightLabel,
  logo,
  subscriptionField,
  subscribeBtnLabel,
  subscribeBtnHandle,
  socialShareTitle,
  socialShareChannels,
  navGroups,
  emailFieldRequiredMsg,
  emailFormatErrorMsg,
  editor,
  checkboxConf,
  subscribeStatus,
  subscribeStatusLabels,
}: FooterProps) => {
  const { isInEditor } = useContext(AppContext);
  const isDesktop = useDesktopQuery();
  enum Fields {
    Email = "email",
    CheckBox = "checkbox",
  }
  const formHook = useForm<{ email: string; checkbox: boolean }>({
    defaultValues: {
      [Fields.Email]: "",
      [Fields.CheckBox]: false,
    },
    schema: yup.object().shape({
      [Fields.Email]: yup.string().required(emailFieldRequiredMsg).matches(EMAIL_REGEXP, {
        message: emailFormatErrorMsg,
        excludeEmptyString: true,
      }),
      [Fields.CheckBox]: yup
        .boolean()
        .required()
        .test("is-checked", "required", (value) => {
          if (value) {
            return true;
          } else {
            return false;
          }
        }),
    }),
    mode: "all",
  });
  const handleCheckboxChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>, check: boolean) => {
      formHook.setValue("checkbox", check, { shouldValidate: true });
    },
    [formHook]
  );
  const onsubmit = (formValues: any) => {
    const { email, checkbox } = formValues.data;
    subscribeBtnHandle(email, checkbox);
    formHook.reset();
  };
  const navList = <nav className={classes.footer_navList}>{navGroups}</nav>;
  const newsSubscription = (
    <div className={classes.footer_newsSubscription}>
      <Title order={TitleOrders.H3Title} color={colors.hkePurple}>
        <Stack
          direction={[subscribeStatus === SubscribeStatus.Success ? "column" : "row", "row"]}
          alignItems={[subscribeStatus === SubscribeStatus.Success ? "flex-start" : "center", "center"]}
          justifyContent='flex-start'
          spacing={ESpacing._xs}
        >
          <Box sx={{ position: "relative", marginRight: "12px !important", top: "5px" }}>
            <SvgIcon
              size={SvgIconSize.Mobile}
              sx={{
                display: subscribeStatus === SubscribeStatus.Success ? "block" : "none",
                position: "absolute",
                top: "-3px",
                right: "-9px",
                color: colors.hkeBlue,
              }}
            >
              <CheckmarkFilledIcon />
            </SvgIcon>
            <SvgIcon
              size={SvgIconSize.Display}
              sx={{
                display: subscribeStatus === SubscribeStatus.Success ? "block" : ["none", "block"],
                fontSize: "2.5rem !important",
              }}
            >
              <EmailIcon />
            </SvgIcon>
          </Box>
          <Font>
            {subscribeStatus === SubscribeStatus.Success ? subscribeStatusLabels.success : subscriptionField.label}
          </Font>
        </Stack>
      </Title>
      {subscribeStatus === SubscribeStatus.Success ? (
        subscribeStatusLabels.successfullyDesc
      ) : (
        <>
          {isDesktop && (
            <>
              <div className={classes.footer_subscribeRow}>
                <div className={classes.footer_subscribeRow_flex}>
                  <FieldInputGroup helperText={subscriptionField.helperText} names={[Fields.Email]} fullWidth>
                    <TextInput fullWidth placeholder={subscriptionField.helperText} type='email' />
                  </FieldInputGroup>
                  {isInEditor && editor.emailFieldRequiredMsg && (
                    <ErrorLabel>{editor.emailFieldRequiredMsg}</ErrorLabel>
                  )}
                  {subscribeStatus === SubscribeStatus.Fail && <ErrorLabel>{subscribeStatusLabels.fail}</ErrorLabel>}
                  {subscribeStatus === SubscribeStatus.Error && <ErrorLabel>{subscribeStatusLabels.error}</ErrorLabel>}
                  {/* <Font marginTop={ESpacing._xs}>{subscribeStatus}</Font> */}
                </div>
                <Button disabled={!formHook.formState.isValid} type='submit'>
                  {subscribeBtnLabel}
                </Button>
              </div>
              <FormControlLabel
                control={<Checkbox onChange={handleCheckboxChange} />}
                label={
                  <FoldableText viewMoreText={checkboxConf.viewMoreLabel} viewlessText={checkboxConf.viewLessLabel}>
                    {parseHtmlToReact(checkboxConf.label)}
                  </FoldableText>
                }
              />
            </>
          )}
          {!isDesktop && (
            <>
              <div>
                <FieldInputGroup helperText={subscriptionField.helperText} names={[Fields.Email]} fullWidth>
                  <TextInput fullWidth placeholder={subscriptionField.helperText} type='email' />
                </FieldInputGroup>
                {isInEditor && editor.emailFieldRequiredMsg && <ErrorLabel>{editor.emailFieldRequiredMsg}</ErrorLabel>}
                {/* <Font marginTop={ESpacing._xs}>{subscribeStatus}</Font> */}
              </div>
              <Button disabled={!formHook.formState.isValid} type='submit'>
                {subscribeBtnLabel}
              </Button>
              <FormControlLabel
                control={<Checkbox />}
                label={
                  <FoldableText viewMoreText={checkboxConf.viewMoreLabel} viewlessText={checkboxConf.viewLessLabel}>
                    {parseHtmlToReact(checkboxConf.label)}
                  </FoldableText>
                }
              />
            </>
          )}
        </>
      )}
    </div>
  );
  const socialShare = (
    <div className={classes.footer_socialShare}>
      <Text color={colors.neutralBlack} size={TextSize.P1Medium}>
        {socialShareTitle}
      </Text>
      <div className={classes.footer_shareChannels}>{socialShareChannels}</div>
    </div>
  );

  return (
    <div>
      <Form formHook={formHook} onSubmit={onsubmit}>
        <div className={classes.footer}>
          {/* {isDesktop && (
            <Section>
              <div className={classes.footer_upper}>
                {navList}
                <div className={classes.footer_communication}>
                  {newsSubscription}
                  {socialShare}
                </div>
              </div>
            </Section>
          )}
          {!isDesktop && (
            <>
              <Section fullWidth>{navList}</Section>
              <Section fullWidth>{newsSubscription}</Section>
              <Section fullWidth>{socialShare}</Section>
            </>
          )} */}
          <Section>
            <Grid container spacing={8}>
              <Grid item sm={12}>
                {navList}
              </Grid>
              <Grid item sm={12} md={6}>
                {newsSubscription}
              </Grid>
              <Grid item sm={12} md={6}>
                {socialShare}
              </Grid>
            </Grid>
          </Section>

          <div className={classes.footer_lowerWrapper}>
            <Section>
              <div className={classes.footer_lower}>
                <div className={classes.footer_logo}>{logo}</div>
                <Text size={TextSize.P3Medium} span color={colors.neutralWhite}>
                  {copyrightLabel}
                </Text>
              </div>
            </Section>
          </div>
        </div>
      </Form>
    </div>
  );
};
